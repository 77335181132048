<template>
  <div class="row justify-content-center">
    <div v-for="question of questions" :key="question.id" :class="questionSize(question.size)">
      <template v-if="isRadio(question.type)">
        <div class="row" v-show="isVisible(question.visible)">
          <div class="flex xs8">
            <p class="question_title" :style="labelStyle">{{ $t(question.title) }}</p>
          </div>
          <div class='flex xs2'>
            <va-radio-button
              :option="true"
              v-model="question.value"
              :label="$t('layout.yes')"
              :disabled="loading || viewOnly"
            />
          </div>
          <div class="flex xs2">
            <va-radio-button
              :option="false"
              v-model="question.value"
              :label="$t('layout.no')"
              :disabled="loading || viewOnly"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <va-input
          color="info"
          v-model="question.value"
          v-show="isVisible(question.visible)"
          :rows="5"
          :type="question.type"
          :disabled="loading || viewOnly"
          :label="$t(question.title)"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'form-builder',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['currentLocale']),
    labelStyle () {
      return { color: this.$themes.primary }
    },
  },
  methods: {
    isRadio (type) {
      return type === 'yes/no'
    },
    isVisible (condition) {
      if (!condition || condition.key === -1) return true

      const question = this.questions.find(q => q.id === condition.key)
      if (!question) return false

      return question.value === condition.value
    },
    questionSize (size) {
      return `flex xs12 ${size}`
    },
  },
}
</script>

<style scoped>
.question_title {
  font-size: 0.625rem;
  font-weight: bold;
  white-space: normal;
  letter-spacing: unset;
  text-transform: uppercase;
}

.justify-content-center {
  justify-content: center !important;
}
</style>
